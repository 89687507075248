import { Card, CardHeader, Divider, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import WalletCard from "../common/walletCard";
import WalletList from "../common/walletList";
import WalletTransaction from "../common/walletTransaction";
import { getCurrentBalance } from "../helpers/utility";
import { getUserInfo } from "../store/auth/auth.selector";
import { fetchPricesRequest, fetchTransactionRequest } from "../store/transaction/transaction.action";
import { getCoinBalance, getStats, getUser } from "../store/user/user.selector";
import { fetchCoinBalanceRequest } from "../store/user/user.action";
import { getCoinPrices } from "../store/transaction/transaction.selector";
export const pageSize = 9;

function MyWallets() {
  const dispatch = useDispatch();
  const [coinBalance, setCoinBalance] = useState({
    "XRP": 0,
    "BTC": 0,
    "BNB": 0,
    "LTC": 0,
    "BUSD": 0,
    "USDT": 0,
    "ETH": 0,
    "TRX": 0,
  });
  const [currentBalance, setCurrentBalance] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");

  const { userInfo, user, prices, balance } = useSelector((state) => {
    return {
      userInfo: getUserInfo(state),
      user: getUser(state),
      balance: getCoinBalance(state),
      prices: getCoinPrices(state)
    }
  });

  useEffect(() => {
    if (selectedItem) {
      dispatch(fetchTransactionRequest(selectedItem));
      setCurrentBalance(coinBalance[selectedItem] || 0);
    }
  }, [selectedItem])


  useEffect(() => {
    dispatch(fetchPricesRequest());
  }, [])

  useEffect(() => {
    if (prices.length > 0) {
      dispatch(fetchCoinBalanceRequest());
    }
  }, [prices]);

  useEffect(() => {
    if (balance.length > 0) {
      var x = {};
      balance.forEach(element => {
        x[element.coinType] = parseFloat(element.coinBalance);
      });
      setCoinBalance(x);
    }
  }, [balance]);

  useEffect(() => {
    async function fetchBalance() {
      if (user && user.wallets && user.wallets.length > 0) {
        _.forEach(user.wallets, async (wallet) => {
          const balance = await getCurrentBalance(wallet.currency, wallet.address);
          const updated = coinBalance;
          updated[wallet.currency] = parseFloat(balance);
          setCoinBalance(updated);
        })
      }
    }
    fetchBalance();
  }, [user.wallets]);


  if (selectedItem) {
    return <div>
      <WalletCard
        selectedItem={selectedItem}
        onClose={() => setSelectedItem("")}
        coinBalance={coinBalance}
        currentBalance={currentBalance} />
      <WalletTransaction selectedItem={selectedItem} />
    </div>
  }

  return (
    <Card variant="outlined">
      <CardHeader avatar={<img src={userInfo.user.photoURL} />}
        subheader="Wallet Information" title={userInfo.user.displayName} />
      <Divider />
      <CardContent>
        <WalletList coinBalance={coinBalance} onChange={(item) => setSelectedItem(item)} />
      </CardContent>
    </Card>
  );
}

export default MyWallets;
