import { call, put, takeEvery } from 'redux-saga/effects';
import * as WithdrawActions from './withdraw.action';
import WithdrawAPI from './withdraw.api';
import { WithdrawActionTypes } from './withdraw.model';

export function* handleFetchWithdraw(action) {
    try {
        const response = yield call(WithdrawAPI.GetWithdraws, action.payload);
        yield put(WithdrawActions.fetchWithdrawSuccess(response));
    } catch (e) {
        yield put(WithdrawActions.fetchWithdrawFailure(e));
    }
}
export function* WithdrawSaga() {
    yield takeEvery(WithdrawActionTypes.FETCH_WITHDRAW_REQUEST, handleFetchWithdraw);
}
