import HttpHelper from "../../helpers/httpHelper";
import { getWithdrawUrl } from "./withdraw.uri";

class WithdrawAPI {
    static async GetWithdraws(status) {
        let url = getWithdrawUrl(status);
        return HttpHelper.httpRequest(url, 'GET', {});
    }
}

export default WithdrawAPI;
