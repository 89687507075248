import { AccountBalance, AccountBalanceWallet, AddCircleOutline, AssignmentInd, Beenhere, CalendarToday, CalendarViewDay, Close, Description, DescriptionOutlined, Email, FileCopy, Fingerprint, OpenInNew, Output, Search, Visibility } from "@mui/icons-material";
import { AppBar, Avatar, Box, Card, CardContent, Paper, CardHeader, Dialog, DialogContent, DialogTitle, Divider, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Select, Tab, Tabs, TextField, DialogActions, Button } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import _ from "underscore";
import Loader from "../common/loader";
import { IsNullOrEmpty, getBlockChainUrl, getLogo, getTransactionUrl, paginate } from "../helpers/utility";
import { getTransactions } from "../store/transaction/transaction.selector";
import TransactionAPI from "../store/transaction/transaction.api";
import { getUser } from "../store/user/user.selector";
import { getTokenBNBBalRequest, updateTokenRequest } from "../store/user/user.action";
import VerifyPin from "./verifyPin";
import { transferCoinRequest } from "../store/transaction/transaction.action";
import Swal from "sweetalert2";
export const pageSize = 9;

function WalletTransaction(props) {
    const { selectedItem } = props;
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [filter, setFilter] = useState("");
    const [datum, setDatum] = useState({});
    const [value, setValue] = React.useState(0);
    const [isAdd, setAdd] = useState(false);
    const [open, setOpen] = useState(false);
    const [tokenAddress, setTokenAddress] = useState("");
    const [symbol, setSymbol] = useState("");
    const [decimals, setDecimals] = useState("");
    const [name, setName] = useState("");
    const [selectedToken, setSelectedToken] = useState("");
    const [toAddress, setAddress] = useState(null);
    const [toAmount, setToAmount] = useState(null);
    const [currentBalance, setCurrentBalance] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { transactions, isLoading, userProfile } = useSelector((state) => {
        return {
            transactions: getTransactions(state),
            userProfile: getUser(state),
            isLoading: state.TransactionReducer.isLoading,
        }
    });

    useEffect(() => {
        setAdd(false);
        setTokenAddress("");
        setSymbol("");
        setDecimals("");
        setName("");
        setSelectedToken("");
    }, [userProfile])

    let filteredList = transactions;
    if (!filteredList) {
        filteredList = [];
    }

    if (!IsNullOrEmpty(filter)) {
        let startTime = moment().startOf('day');
        let endTime = moment().endOf('day');
        switch (filter) {
            case "Today":
                filteredList = filteredList.filter((x) => {
                    return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
                })
                break;
            case "Week":
                startTime = moment().startOf("weeks");
                endTime = moment().endOf('weeks');
                filteredList = filteredList.filter((x) => {
                    return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
                })
                break;
            case "Month":
                startTime = moment().startOf('month');
                endTime = moment().endOf('month');
                filteredList = filteredList.filter((x) => {
                    return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
                })
                break;
            case "Year":
                startTime = moment().startOf('year');
                endTime = moment().endOf('year');
                filteredList = filteredList.filter((x) => {
                    return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
                })
                break;
        }
    }

    filteredList = _.sortBy(filteredList, "createdOn").reverse();
    let totalPages = Math.ceil(filteredList.length / pageSize);
    filteredList = paginate(filteredList, pageSize, page);

    function handleSend() {
        if (!isDisabled) {
            if (parseFloat(toAmount) < parseFloat(currentBalance)) {
                setOpen(true);
            }
            else {
                Swal.fire({
                    title: "Validation",
                    text: "Insufficient Balance to transfer",
                    icon: "error"
                });
            }
        }
    }

    if (open) {
        return <VerifyPin
            id={userProfile.id}
            onClose={() => {
                setOpen(false);
            }}
            onComplete={(ref) => {
                dispatch(transferCoinRequest({
                    tokenAddress: tokenAddress,
                    address: toAddress,
                    coins: toAmount,
                    type: selectedItem
                }))
                setOpen(false);
            }} />
    }

    let isDisabled = IsNullOrEmpty(toAddress) ||
        IsNullOrEmpty(toAmount) ||
        isNaN(parseFloat(toAmount)) ||
        parseFloat(toAmount) > parseFloat(currentBalance);

    return <div>
        {
            isLoading && <Loader />
        }
        <div>
            <Paper square className="mt-2">
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    centered

                >
                    <Tab label="Transaction history" />
                    <Tab label="Assets" />
                </Tabs>
            </Paper>
            {
                value === 0 &&
                <div>
                    {
                        isLoading && <Loader />
                    }
                    {
                        datum.id &&
                        <Dialog maxWidth="md" fullWidth open={datum.id}>
                            <DialogTitle>
                                Transaction Information
                                <Close className="float-right mt-2" onClick={() => {
                                    setDatum({});
                                }} />
                            </DialogTitle>
                            <Divider />
                            <DialogContent>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Email />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary='Customer Email' primary={datum.email} />
                                                {
                                                    navigator && navigator.clipboard &&
                                                    <ListItemSecondaryAction>
                                                        <FileCopy color="secondary" onClick={() => {
                                                            navigator.clipboard.writeText(datum.email);
                                                            toastr.success("Copied Address to clipboard");
                                                        }} />
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Output />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary='Status' primary={datum.status} />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <AccountBalance />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary={`Transaction ${datum.tokenSymbol ? "Tokens" : "Coins"}`} primary={`${datum.coins} ${datum.tokenSymbol || datum.type}`} />
                                                {
                                                    navigator && navigator.clipboard &&
                                                    <ListItemSecondaryAction>
                                                        <FileCopy color="secondary" onClick={() => {
                                                            navigator.clipboard.writeText(datum.coins);
                                                            toastr.success("Copied Address to clipboard");
                                                        }} />
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <AccountBalanceWallet />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary='Transaction Charge' primary={`${datum.transactionFees && parseFloat(datum.transactionFees).toFixed(8)} ${datum.tokenSymbol || datum.type}`} />
                                                {
                                                    navigator && navigator.clipboard &&
                                                    <ListItemSecondaryAction>
                                                        <FileCopy color="secondary" onClick={() => {
                                                            navigator.clipboard.writeText(datum.transactionCharge);
                                                            toastr.success("Copied Address to clipboard");
                                                        }} />
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Fingerprint />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary='Transaction ID' primary={datum.id} />
                                                {
                                                    navigator && navigator.clipboard &&
                                                    <ListItemSecondaryAction>
                                                        <FileCopy color="secondary" onClick={() => {
                                                            navigator.clipboard.writeText(datum.id);
                                                            toastr.success("Copied Address to clipboard");
                                                        }} />
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Description />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary='Reference #' primary={datum.txnid} />
                                                {
                                                    navigator && navigator.clipboard &&
                                                    <ListItemSecondaryAction>
                                                        <FileCopy color="secondary" onClick={() => {
                                                            navigator.clipboard.writeText(datum.txnid);
                                                            toastr.success("Copied Address to clipboard");
                                                        }} />
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </List>
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <CalendarToday />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary='Created On' primary={moment.unix(datum.createdOn._seconds).format("lll")} />
                                                {
                                                    <ListItemSecondaryAction>
                                                        {moment.unix(datum.createdOn._seconds).fromNow()}
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <CalendarViewDay />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary='Last Modified On' primary={moment.unix(datum.updatedOn._seconds).format("lll")} />
                                                {
                                                    <ListItemSecondaryAction>
                                                        {moment.unix(datum.updatedOn._seconds).fromNow()}
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Fingerprint />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary='Transaction Hash' primary={datum.transactionhash} />
                                                {
                                                    datum && datum.transactionhash &&
                                                    <ListItemSecondaryAction>
                                                        <a target='_blank' href={getTransactionUrl(datum.type) + datum.transactionhash}>
                                                            <OpenInNew color="primary" />
                                                        </a>
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <List disablePadding>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Fingerprint />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText secondary='Transaction Charge Hash' primary={datum.parentTransactionHash} />
                                                {
                                                    datum && datum.parentTransactionHash &&
                                                    <ListItemSecondaryAction>
                                                        <a target='_blank' href={getTransactionUrl(datum.type) + datum.parentTransactionHash}>
                                                            <OpenInNew color="primary" />
                                                        </a>
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    }
                    <Grid container spacing={2} className="my-2">
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box display={"flex"} width="100%" justifyContent={"space-between"} alignContent={"center"}>
                                <div>
                                    <TextField
                                        variant="outlined"
                                        className="rounded-lg"
                                        style={{ marginTop: 0 }}
                                        margin="dense"
                                        size="small"
                                        value={searchText}
                                        onChange={(e) => {
                                            setSearchText(e.target.value)
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Search />
                                            </InputAdornment>
                                        }}
                                        placeholder="Search..."
                                    />
                                </div>
                                <Select
                                    native
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    value={filter}
                                    onChange={(e) => [
                                        setFilter(e.target.value)
                                    ]}
                                >
                                    <option value="">All Time</option>
                                    <option value="Today">Today</option>
                                    <option value="Week">This Week</option>
                                    <option value="Month">This Month</option>
                                    <option value="Year">This Year</option>
                                </Select>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={2}>
                                {
                                    filteredList.map((dep) => {
                                        return <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <Card variant="outlined" style={{ opacity: dep.status === "Approved" ? 1 : 0.7 }}>
                                                <CardHeader
                                                    avatar={<img style={{ height: 32, width: 32 }}
                                                        src={getLogo(dep.type).logo} />}
                                                    classes={{
                                                        root: "p-1",
                                                        action: "px-3 py-2"
                                                    }}
                                                    title={dep.status}
                                                    subheader={`${dep.amount} USD`}
                                                    action={
                                                        <Visibility
                                                            className="cursor"
                                                            color="info"
                                                            onClick={() => {
                                                                setDatum(dep);
                                                            }}
                                                        />
                                                    }
                                                    style={{ background: dep.status === "Approved" ? "#bcf5bc" : "inherit" }}
                                                />
                                                <Divider />
                                                <CardContent className="p-0">
                                                    <table className="table-striped text-medium border-none">
                                                        <tr>
                                                            <td>Transfered {dep.tokenSymbol ? "Tokens" : "Coins"}</td>
                                                            <td >
                                                                {
                                                                    dep.transactionhash ?
                                                                        <a className="text-primary font-weight-bolder text-decoration-none" target="_blank" href={getBlockChainUrl(dep.type) + dep.transactionhash}>{`${dep.coins} ${dep.tokenSymbol || dep.type}`}</a> :
                                                                        `${dep.coins} ${dep.tokenSymbol || dep.type}`
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Transaction Charge</td>
                                                            <td>
                                                                {
                                                                    dep.parentTransactionHash ?
                                                                        <a className="text-info font-weight-bolder text-decoration-none" target="_blank" href={getBlockChainUrl(dep.type) + dep.parentTransactionHash}>{`${dep.transactionFees && parseFloat(dep.transactionFees).toFixed(8)} ${dep.type}`}</a> :
                                                                        `${dep.transactionFees && parseFloat(dep.transactionFees).toFixed(8)} ${dep.type}`
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Transaction Time</td>
                                                            <td>{moment.unix(dep.createdOn._seconds).format("lll")}</td>
                                                        </tr>
                                                    </table>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid xs={12} sm={12}>
                            {
                                filteredList.length > 0 &&
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} className="my-2">
                                    <Pagination
                                        shape="rounded"
                                        variant="outlined"
                                        count={totalPages}
                                        page={page}
                                        color="primary"
                                        onChange={(e, pg) => {
                                            setPage(pg)
                                        }} />
                                </Box>
                            }
                            {
                                filteredList.length === 0 && !isLoading && <p className="text-center">No records available</p>
                            }
                        </Grid>
                    </Grid>
                </div>
            }
            {
                value === 1 && <div>
                    <h2 className="py-0 my-2">Assets</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Card variant="outlined">
                                <CardContent onClick={() => setAdd(true)}>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"250px"}>
                                        <AddCircleOutline style={{ fontSize: "8rem" }} color="secondary" />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        {
                            userProfile.tokens && userProfile.tokens.map((s) => {
                                return <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Card variant="outlined">
                                        <CardContent className="p-2 pb-2">
                                            <List disablePadding>
                                                <ListItem disableGutters className="py-0">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <Fingerprint />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText title={s.address} primaryTypographyProps={{
                                                        className: "overflow-text"
                                                    }} secondary='Contract Address' primary={s.address} />
                                                    {
                                                        navigator && navigator.clipboard &&
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(s.address);
                                                                toastr.success("Copied Address to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    }
                                                </ListItem>
                                                <ListItem disableGutters className="py-0">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <DescriptionOutlined />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText secondary='Name' primary={s.name} />
                                                </ListItem>
                                                <ListItem disableGutters className="py-0">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <AssignmentInd />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText secondary='Symbol' primary={s.symbol} />
                                                </ListItem>
                                                <ListItem disableGutters className="py-0">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <Beenhere />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText secondary='Decimals' primary={s.decimals} />
                                                    <ListItemSecondaryAction>
                                                        <Button onClick={() => {
                                                            let isExist = _.find(userProfile.wallets, s => s.currency === selectedItem);
                                                            dispatch(getTokenBNBBalRequest({
                                                                address: isExist.address,
                                                                tokenAddress: s.address
                                                            }))
                                                        }} variant="outlined" size="small">
                                                            Get Balance
                                                        </Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <ListItem disableGutters className="py-0">
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <AccountBalance />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText secondary='Balance' primary={s.balance} />
                                                    <ListItemSecondaryAction>
                                                        <Button disabled={s.balance === "***" || s.balance == 0} onClick={() => {
                                                            setCurrentBalance(s.balance);
                                                            setTokenAddress(s.address);
                                                            setSelectedToken(s.address);
                                                        }} variant="outlined" size="small">
                                                            Withdraw
                                                        </Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </List>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            })
                        }
                        <Dialog maxWidth="sm" fullWidth open={isAdd}>
                            <DialogTitle>
                                Token Information
                                <Close className="float-right mt-2" onClick={() => {
                                    setAdd(false);
                                }} />
                            </DialogTitle>
                            <Divider />
                            <DialogContent>
                                <TextField
                                    variant="outlined"
                                    className="rounded-lg"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    value={tokenAddress}
                                    onChange={(e) => {
                                        setTokenAddress(e.target.value)
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">
                                            <Search onClick={() => {
                                                TransactionAPI.GetTokenBalances([tokenAddress])
                                                    .then((s) => {
                                                        if (s && s.length > 0) {
                                                            setDecimals(s[0].decimals);
                                                            setName(s[0].name);
                                                            setSymbol(s[0].symbol);
                                                        }
                                                        else {
                                                            toastr.error("Please enter proper contract address");
                                                        }
                                                    })
                                            }} />
                                        </InputAdornment>
                                    }}
                                    label="Token Address"
                                />
                                <TextField
                                    variant="outlined"
                                    className="rounded-lg"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    disabled
                                    value={name}
                                    label="Token Name"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    className="rounded-lg"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    disabled
                                    value={symbol}
                                    label="Token Symbol"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    className="rounded-lg"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    disabled
                                    value={decimals}
                                    placeholder="Token Decimals"
                                    label="Token Decimals"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
                                    <Button variant="outlined" onClick={() => {
                                        setTokenAddress("");
                                        setName("");
                                        setSymbol("");
                                        setDecimals("");
                                    }}>
                                        Reset
                                    </Button>
                                    <Button onClick={() => {
                                        dispatch(
                                            updateTokenRequest({
                                                token: {
                                                    address: tokenAddress,
                                                    name: name,
                                                    symbol: symbol,
                                                    decimals: decimals,
                                                    coinType: selectedItem,
                                                    balance: "***"
                                                }
                                            }))
                                    }} variant="contained" color="primary" disabled={IsNullOrEmpty(symbol)}>
                                        Add Now
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Dialog>
                        <Dialog maxWidth="sm" fullWidth open={!IsNullOrEmpty(selectedToken)}>
                            <DialogTitle>
                                Withdraw Information
                                <Close className="float-right mt-2" onClick={() => {
                                    setSelectedToken("");
                                }} />
                            </DialogTitle>
                            <Divider />
                            <DialogContent>
                                <TextField
                                    label="Address"
                                    fullWidth
                                    value={toAddress}
                                    size="small"
                                    margin="dense"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setAddress(e.target.value)
                                    }}
                                    className="my-2"
                                />
                                <TextField
                                    label="Coins"
                                    fullWidth
                                    value={toAmount}
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setToAmount(e.target.value)
                                    }}
                                    className="my-2"
                                />
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        setSelectedToken("");
                                    }}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" color="secondary" disabled={isDisabled} onClick={() => {
                                        handleSend()
                                    }}>
                                        Send
                                    </Button>
                                </Box>

                            </DialogContent>
                        </Dialog>
                    </Grid>
                </div>
            }
        </div>
    </div>
}

export default WalletTransaction;
